// On document.ready
$(document).ready(function () {
    contactBox();
});

// Contact box toggle
function contactBox() {
    $( ".contact-box button" ).click(function() {
        $( ".contact-box .panel" ).toggle();
    });

    $( ".contact-box .icon-cross" ).click(function() {
        $( ".contact-box .panel" ).toggle();
    });
}
