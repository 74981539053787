$(document).ready(function () {
    jsToggle();
});

// Toggle element on click/mouseover
function jsToggle() {
    $('[data-toggle-target]').each(function(e) {
        let toggleParentElement = $(this).attr('data-toggle-target-parent');
        let toggleElement = $(this).attr('data-toggle-target');
        let toggleClass = $(this).attr('data-toggle-target-class');
        let toggleType = $(this).attr('data-toggle-type');

        // check if data-toggle-target-class is empty
        if(toggleClass != null) {

            // toggle on hover
            if (toggleType === 'hover') {
                $(this).on('mouseenter', function() {

                    if(toggleElement === 'this') {
                        $(this).toggleClass(toggleClass);
                    } else if(toggleElement === 'parent') {
                        $(this).parent().toggleClass(toggleClass);
                    } else {
                        // if theres a parent element
                        if(toggleParentElement != null) {

                            // if its parent
                            if(toggleParentElement === 'parent') {
                                $(this).parent().find(toggleElement).toggleClass(toggleClass);

                            } else if(toggleParentElement === 'this') {
                                $(this).find(toggleElement).toggleClass(toggleClass);

                            } else {
                                $(this).closest(toggleParentElement).find(toggleElement).toggleClass(toggleClass);

                            }
                        } else {
                            $(toggleElement).toggleClass(toggleClass);
                        }
                    }
                });
                $(this).on('mouseleave', function() {

                    if(toggleElement === 'this') {
                        $(this).toggleClass(toggleClass);
                    } else if(toggleElement === 'parent') {
                        $(this).parent().toggleClass(toggleClass);
                    } else {
                        // if theres a parent element
                        if (toggleParentElement != null) {

                            // if its parent
                            if (toggleParentElement === 'parent') {
                                $(this).parent().find(toggleElement).toggleClass(toggleClass);

                            } else if (toggleParentElement === 'this') {
                                $(this).find(toggleElement).toggleClass(toggleClass);

                            } else {
                                $(this).closest(toggleParentElement).find(toggleElement).toggleClass(toggleClass);

                            }
                        } else {
                            $(toggleElement).toggleClass(toggleClass);
                        }
                    }
                });

                // toggle on change
            } else if (toggleType === 'change') {
                $(this).on('change', function() {
                    if ($(this).is(":checked")) {
                        $(toggleElement).addClass(toggleClass);
                    } else {
                        $(toggleElement).removeClass(toggleClass);
                    }
                });

                // toggle on click
            } else {
                $(this).on('click', function(e) {
                    e.preventDefault();

                    if(toggleElement === 'this') {
                        $(this).toggleClass(toggleClass);
                    } else if(toggleElement === 'parent') {
                        $(this).parent().toggleClass(toggleClass);
                    } else {
                        // if theres a parent element
                        if(toggleParentElement != null) {

                            // if its parent
                            if(toggleParentElement === 'parent') {
                                $(this).parent().find(toggleElement).toggleClass(toggleClass);

                            } else {
                                $(this).closest(toggleParentElement).find(toggleElement).toggleClass(toggleClass);

                            }
                        } else {
                            $(toggleElement).toggleClass(toggleClass);
                        }
                    }
                });

            }
        } else {
            // console.log(toggleElement +': Set [data-toggle-target-class]!')
        }
    });
}
