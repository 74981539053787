$(document).ready(function () {
    enableFixedHeader();
});

$(window).on('scroll', function () {
    enableFixedHeader();
});

// Fixed header
function enableFixedHeader() {
    if (g_scroll >= 1) {
        $('[data-fixed-header]').addClass('fixed');
    } else {
        $('[data-fixed-header]').removeClass('fixed');
    }
}
